import React from 'react';
import styles from './about.module.css';

const About = () => {
    return (
        <main>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>About Us</h1>
                <p className={styles.articleIntro}>CityTrendz.net showcases top cities through beautifully designed articles:</p>
                <ul className={styles.bulletedList} style={{listStyle: 'disc'}}>
                    <li>Top 10 lists to aggregate the best cities in different categories</li>
                    <li>"Local Guide" lists to showcase top attractions of individual cities</li>
                </ul>
                <p className={styles.articleIntro}>CityTrendz.net is modern, elegant, attractive and fully responsive on all devices.  We use Artificial Intelligence to optimize the content and user experience for each page.</p>
                <p className={styles.articleIntro}>We envision that many cities will eventually have their own CityTrendz.net Local Guide page as a standard part of their portfolio - similar to having their own social media page on different platforms.</p>

            </div>
        </main>
    );
};

export default About;
