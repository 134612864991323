import React from 'react';
import './5-things-to-do.css';
import imageComingSoonCamera from './IMAGE-COMING-SOON-CAMERA.png';

const FiveThingsToDo = () => {
    return (
        <main>  
            <div className="content-container">
                <h1 className="page-title">5 Things To Do in Dublin, OH</h1>
                <p className="article-intro">
                Discover the charm of Dublin, OH through our curated list of five great activities. Immerse yourself in local history, explore beautiful green spaces, and engage with the unique cultural spots that make Dublin so appealing.
                </p>
                <ol>
                    <li className="list-item">
                        <div style={{textAlign: 'center', width: '100%', paddingBottom: '5px'}}>
                            <div className="list-item-number">5</div>
                        </div>
                        <div className="list-item-content">
                            <h2 className="list-item-title">
                                <div className="list-item-number-inline" style={{fontWeight: 'normal'}}>5</div>
                                Historic Dublin
                            </h2>
                        </div>
                        <p>Explore the charm of <a href="https://www.visitdublinohio.com/listing/historic-dublin/206/">Historic Dublin</a>, a perfect blend of past and present with unique shops and local eateries.</p>
                        <img src={imageComingSoonCamera} alt="Historic Dublin Streets" className="list-item-image" />
                        <ul>
                            <li className="sub-item"><span className="list-item-title">Highlight:</span>
                                <span className="list-item-content">Quaint architecture and vibrant local markets.</span>
                            </li>
                            <li className="sub-item"><span className="list-item-title">Must Visit:</span>
                                <span className="list-item-content"><a href="http://bridgestreet.dublinohiousa.gov/pedestrian-bridge">Dublin Link Bridge</a> for picturesque views and modern design.</span>
                            </li>
                            <li className="sub-item"><span className="list-item-title">Local Favorite:</span>
                                <span className="list-item-content">Enjoy live music during the weekend evenings.</span>
                            </li>
                        </ul>
                        <div className="clear"></div>
                    </li>

                    <li className="list-item">
                        <div style={{textAlign: 'center', width: '100%', paddingBottom: '5px'}}>
                            <div className="list-item-number">4</div>
                        </div>
                        <div className="list-item-content">
                            <h2 className="list-item-title">
                                <div className="list-item-number-inline" style={{fontWeight: 'normal'}}>4</div>
                                Scioto Park
                            </h2>
                        </div>
                        <p>Enjoy outdoor activities and stunning river views at <a href="https://dublinohiousa.gov/recreation-services/parks/">Scioto Park</a>, a favorite spot for families and adventurers alike.</p>
                        <img src={imageComingSoonCamera} alt="Scioto Park Scenery" className="list-item-image" />
                        <ul>
                            <li className="sub-item"><span className="list-item-title">Highlight:</span>
                                <span className="list-item-content">Large open spaces and well-maintained picnic areas.</span>
                            </li>
                            <li className="sub-item"><span className="list-item-title">Activities:</span>
                                <span className="list-item-content">Kayaking, paddle boarding, and scenic hiking trails.</span>
                            </li>
                            <li className="sub-item"><span className="list-item-title">Events:</span>
                                <span className="list-item-content">Check the park calendar for seasonal festivals and concerts.</span>
                            </li>
                        </ul>
                        <div className="clear"></div>
                    </li>

                    <li className="list-item">
                        <div style={{textAlign: 'center', width: '100%', paddingBottom: '5px'}}>
                            <div className="list-item-number">3</div>
                        </div>
                        <div className="list-item-content">
                            <h2 className="list-item-title">
                                <div className="list-item-number-inline" style={{fontWeight: 'normal'}}>3</div>
                                Indian Run Falls
                            </h2>
                        </div>
                        <p>Explore the natural beauty of Dublin with a visit to <a href="https://www.tripadvisor.com/Attraction_Review-g50285-d4309641-Reviews-Indian_Run_Falls-Dublin_Ohio.html">Indian Run Falls</a>, a serene park featuring picturesque waterfalls and walking trails.</p>
                        <img src={imageComingSoonCamera} alt="Indian Run Falls" className="list-item-image" />
                        <ul>
                            <li className="sub-item"><span className="list-item-title">Highlight:</span>
                                <span className="list-item-content">Stunning waterfalls and lush greenery, perfect for nature photography.</span>
                            </li>
                            <li className="sub-item"><span className="list-item-title">Special Tip:</span>
                                <span className="list-item-content">Follow the marked trails for a scenic loop around the falls.</span>
                            </li>
                            <li className="sub-item"><span className="list-item-title">Quiet Escape:</span>
                                <span className="list-item-content">Visit during the weekday mornings for a peaceful experience.</span>
                            </li>
                        </ul>
                        <div className="clear"></div>
                    </li>

                    <li className="list-item">
                        <div style={{textAlign: 'center', width: '100%', paddingBottom: '5px'}}>
                            <div className="list-item-number">2</div>
                        </div>
                        <div className="list-item-content">
                            <h2 className="list-item-title">
                                <div className="list-item-number-inline" style={{fontWeight: 'normal'}}>2</div>
                                Golf Club of Dublin
                            </h2>
                        </div>
                        <p>Experience the lush greens and challenging fairways at the <a href="https://www.golfclubofdublin.com/">Golf Club of Dublin</a>, the foremost public golf course in the area.</p>
                        <img src={imageComingSoonCamera} alt="Golf Club of Dublin" className="list-item-image" />
                        <ul>
                            <li className="sub-item"><span className="list-item-title">Highlight:</span>
                                <span className="list-item-content">Impeccably maintained 18-hole course with a classic Irish layout.</span>
                            </li>
                            <li className="sub-item"><span className="list-item-title">Recommendation:</span>
                                <span className="list-item-content">Visit the clubhouse for a relaxing end to your round.</span>
                            </li>
                            <li className="sub-item"><span className="list-item-title">Pro Tip:</span>
                                <span className="list-item-content">Take advantage of the golf pro lessons available for all skill levels.</span>
                            </li>
                        </ul>
                        <div className="clear"></div>
                    </li>

                    <li className="list-item">
                        <div style={{textAlign: 'center', width: '100%', paddingBottom: '5px'}}>
                            <div className="list-item-number">1</div>
                        </div>
                        <div className="list-item-content">
                            <h2 className="list-item-title">
                                <div className="list-item-number-inline" style={{fontWeight: 'normal'}}>1</div>
                                Avery Park
                            </h2>
                        </div>
                        <p>Avery Park provides a serene environment perfect for a peaceful day outdoors, with extensive walking trails and sports facilities.</p>
                        <img src={imageComingSoonCamera} alt="Avery Park" className="list-item-image" />
                        <ul>
                            <li className="sub-item"><span className="list-item-title">Highlight:</span>
                                <span className="list-item-content">Beautiful walking paths and multiple sport courts.</span>
                            </li>
                            <li className="sub-item"><span className="list-item-title">For the Kids:</span>
                                <span className="list-item-content">Spacious playgrounds and safe, fun environments for children.</span>
                            </li>
                            <li className="sub-item"><span className="list-item-title">Nature Spot:</span>
                                <span className="list-item-content">Visit the butterfly garden for a quiet moment amidst colorful wings.</span>
                            </li>
                        </ul>
                        <div className="clear"></div>
                    </li>
                </ol>
            </div>
        </main>
    );
};

export default FiveThingsToDo;
