import React from 'react';
import styles from './top-10-foodie-cities.module.css';
import nycFoodieScene from '../../../images/nyc-foodie-scene.webp';
import hollywoodFoodieScene from '../../../images/hollywood-foodie-scene.webp';
import chicagoFoodieScene from '../../../images/chicago-foodie-scene-3.webp';
import sfFoodieScene from '../../../images/sf-foodie-scene-2.webp';
import noFoodieScene from '../../../images/no-foodie-scene-2.webp';
import pdxFoodieScene from '../../../images/portland-foodie-scene-2.webp';
import atxFoodieScene from '../../../images/austin-foodie-scene.webp';
import seattleFoodieScene from '../../../images/seattle-foodie-scene-2.webp';
import miamiFoodieScene from '../../../images/miami-foodie-scene.webp';
import phillyFoodieScene from '../../../images/philly-foodie-scene.webp';

const Top10FoodieCities = () => {
    return (
        <main>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>10 Cities in the US with the Best Foodie Scene in 2024</h1>
                <p className={styles.articleIntro}>
                    Discover the top 10 US cities for food lovers in our guide. Explore a spectrum of culinary experiences, from bustling street food to exquisite fine dining. Get ready for a delicious journey through the best foodie destinations to watch in 2024.
                </p>
                <ol>
                    {/* Top 10 Item */}
                    <li className={styles.listItem}>
                        <div style={{ height: 6 }}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{ fontWeight: 'normal' }}>10</span>
                            New York City, NY
                        </h2>
                        <p>From classic bagels to fine dining, NYC is a foodie's dream come true.</p>
                        <img src={nycFoodieScene} alt="New York City Food Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Diverse international cuisines and pioneering dining trends.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Eats:</span>
                                <span className={styles.listItemContent}>Enjoy cutting-edge vegan cuisine at <a href="https://www.elevenmadisonpark.com/">Eleven Madison Park</a>, classic New York pizza at <a href="https://www.difaras.com">Di Fara</a>.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Culinary Experience:</span>
                                <span className={styles.listItemContent}>Food tours through diverse neighborhoods like Queens for authentic global eats.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{ height: 6 }}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{ fontWeight: 'normal' }}>9</span>
                            Los Angeles, CA
                        </h2>
                        <p>Savor the fusion of flavors where health-conscious dishes meet innovative street food.</p>
                        <img src={hollywoodFoodieScene} alt="Los Angeles Food Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Fusion cuisines and health-conscious innovations.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Eats:</span>
                                <span className={styles.listItemContent}><a href="https://bestiala.com/">Bestia</a> for Italian, <a href="https://www.ilovemole.com/">Guelaguetza</a> for Oaxacan dishes.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Culinary Experience:</span>
                                <span className={styles.listItemContent}>Explore the farmers markets, sushi rolling classes in Little Tokyo.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{ height: 6 }}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{ fontWeight: 'normal' }}>8</span>
                            Chicago, IL
                        </h2>
                        <p>Explore a city where iconic comfort foods meet boundary-pushing culinary arts.</p>
                        <img src={chicagoFoodieScene} alt="Chicago Food Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Renowned for deep-dish pizza and innovative fine dining.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Eats:</span>
                                <span className={styles.listItemContent}><a href="https://www.alinearestaurant.com/">Alinea</a> for avant-garde dishes, <a href="https://www.loumalnatis.com/">Lou Malnati's</a> for deep-dish pizza.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Culinary Experience:</span>
                                <span className={styles.listItemContent}>Chicago food tours including the famous <a href="https://www.chicago.gov/city/en/depts/dca/supp_info/taste_of_chicago.html">Taste of Chicago</a> food festival.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{ height: 6 }}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{ fontWeight: 'normal' }}>7</span>
                            San Francisco, CA
                        </h2>
                        <p>San Francisco boasts a dynamic culinary landscape, characterized by its innovative approaches and diverse food offerings that cater to every palate.</p>
                        <img src={sfFoodieScene} alt="San Francisco Food Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Local and sustainable ingredients with an emphasis on organic produce.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Eats:</span>
                                <span className={styles.listItemContent}>Tasting menu at <a href="https://www.benusf.com/">Benu</a>, sourdough bread at <a href="https://tartinebakery.com/">Tartine Bakery</a>.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Culinary Experience:</span>
                                <span className={styles.listItemContent}>Seafood at Fisherman’s Wharf, Mission District’s burrito tour.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{ height: 6 }}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{ fontWeight: 'normal' }}>6</span>
                            New Orleans, LA
                        </h2>
                        <p>Experience the vibrant flavors where European traditions blend with Southern charm.</p>
                        <img src={noFoodieScene} alt="New Orleans Food Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Celebrated for its rich culinary heritage and diverse flavor profiles.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Eats:</span>
                                <span className={styles.listItemContent}><a href="https://www.commanderspalace.com/">Commander's Palace</a> for Creole dishes, beignets at <a href="https://shop.cafedumonde.com/">Café Du Monde</a>.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Culinary Experience:</span>
                                <span className={styles.listItemContent}>Cajun and Creole cooking classes, French Quarter food tours.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{ height: 6 }}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{ fontWeight: 'normal' }}>5</span>
                            Portland, OR
                        </h2>
                        <p>Discover the heart of Pacific Northwest cuisine through its pioneering farm-to-table practices.</p>
                        <img src={pdxFoodieScene} alt="Portland Food Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Farm-to-table eateries and a booming food cart culture.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Eats:</span>
                                <span className={styles.listItemContent}>Tasting menu at <a href="https://www.lepigeon.com/">Le Pigeon</a>, eclectic food carts at Portland Food </span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Culinary Experience:</span>
                                <span className={styles.listItemContent}>Urban winery tours, local brewery tastings.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{ height: 6 }}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{ fontWeight: 'normal' }}>4</span>
                            Austin, TX
                        </h2>
                        <p>Austin's culinary scene thrives on a blend of smoky barbecue traditions, innovative Tex-Mex, and a lively array of food trucks. It's a city where the flavors are as vibrant as the live music scene.</p>
                        <img src={atxFoodieScene} alt="Austin Food Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Barbecue and Tex-Mex, with a growing scene of innovative casual dining.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Eats:</span>
                                <span className={styles.listItemContent}><a href="https://franklinbbq.com/">Franklin Barbecue</a> for smoked meats, <a href="https://torchystacos.com/">Torchy’s Tacos</a> for Tex-Mex.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Culinary Experience:</span>
                                <span className={styles.listItemContent}>BBQ pit tours, live music with dining on Rainey Street.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{ height: 6 }}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{ fontWeight: 'normal' }}>3</span>
                            Seattle, WA
                        </h2>
                        <p>Seattle's foodie profile is a tapestry of fresh, local seafood and a renowned coffee culture, framed by the iconic Pike Place Market and artisanal roasteries.</p>
                        <img src={seattleFoodieScene} alt="Seattle Food Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Fresh seafood and a strong coffee culture.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Eats:</span>
                                <span className={styles.listItemContent}><a href="https://thewalrusbar.com/">The Walrus and the Carpenter</a> for oysters, <a href="https://www.pikeplacechowder.com/">Pike Place Chowder</a>.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Culinary Experience:</span>
                                <span className={styles.listItemContent}>Pike Place Market tour, coffee tastings at local roasteries.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>

                    <li className={styles.listItem}>
                        <div style={{ height: 6 }}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{ fontWeight: 'normal' }}>2</span>
                            Miami, FL
                        </h2>
                        <p>Miami's culinary scene bursts with vibrant Latin flavors and a rich array of fresh seafood, embodying the city's lively and diverse food culture.</p>
                        <img src={miamiFoodieScene} alt="Miami Food Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Vibrant Latin American influences with a flair for seafood.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Eats:</span>
                                <span className={styles.listItemContent}><a href="https://joesstonecrab.com/">Joe's Stone Crab</a> for seafood, <a href="https://www.versaillesrestaurant.com/">Versailles</a> for Cuban cuisine.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Culinary Experience:</span>
                                <span className={styles.listItemContent}>Little Havana food tour, stone crab tastings.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>

                    <li className={styles.listItem}>
                        <div style={{ height: 6 }}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{ fontWeight: 'normal' }}>1</span>
                            Philadelphia, PA
                        </h2>
                        <p>Philadelphia's culinary scene offers a rich mix of inventive American eats and international flavors, far beyond its iconic cheesesteaks.</p>
                        <img src={phillyFoodieScene} alt="Philadelphia Food Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>More than just cheesesteaks, a hub for inventive American eats.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Eats:</span>
                                <span className={styles.listItemContent}><a href="https://www.zahavrestaurant.com">Zahav</a> for Israeli cuisine, <a href="https://www.patskingofsteaks.com/">Pat's King of Steaks</a> for cheesesteak.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Culinary Experience:</span>
                                <span className={styles.listItemContent}>Historic market tours, diverse East Passyunk dining corridor.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                </ol>
            </div>
        </main>
    );
};

export default Top10FoodieCities;
