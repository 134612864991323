import React, { useState, useRef } from 'react';
import headerStyles from './header.module.css';
import menuStyles from './menu.module.css';
import { Link } from 'react-router-dom';

const Header = () => {
  const [navVisible, setNavVisible] = useState(false);
  const headerRef = useRef(null);

  const toggleNav = () => {
    setNavVisible(!navVisible);
  };

  return (
    <header ref={headerRef} className={headerStyles.header}>
      <div>
        <div className={headerStyles.invisibleSpacer}></div>
        <a href="/" style={{ color: 'inherit', textDecoration: 'none' }}>
          <h1>CityTrendz.net</h1>
        </a>
        <div
          className={menuStyles.hamburgerMenu}
          onClick={toggleNav}
          onMouseEnter={() => setNavVisible(true)}
          onMouseLeave={() => setNavVisible(false)}
        >
          &#9776;
          <ul
            id="nav-links"
            className={menuStyles.navLinks}
            style={{ display: navVisible ? 'block' : 'none' }}
          >
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/local-guides">Local Guides</Link></li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
