import React from 'react';
import styles from './local-guides.module.css';
import { Link } from 'react-router-dom';

const LocalGuides = () => {
    return (
        <main>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>Local Guides</h1>
                <p className={styles.articleIntro}>
                    The landing page of the Local Guide for a city can be accessed in the format of <a href="">www.citytrendz.net/city-statecode</a>.
                    For example, the landing page for Abilene, TX would be accessed as <Link to="/abilene-tx">www.citytrendz.net/abilene-tx</Link>.
                    <br/><br/>
                    Are you interested in a Local Guide for a city that is not currently available via that URL format?  
                    Feel free to reach out to us at <a href="mailto:info@CityTrendz.net">info@CityTrendz.net</a> so we can discuss the design of a custom Local Guide page for that city!
                    <br/><br/>
                </p>
            </div>
        </main>
    );
};

export default LocalGuides;
