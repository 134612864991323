import React from 'react';
import styles from './5-things-to-do.module.css';
import comingSoonCamera from './IMAGE-COMING-SOON-CAMERA.png';
import frontierTX from '../../../../images/abilene/frontier-tx.jpeg';
import graceMuseumCropped from '../../../../images/abilene/the-grace-museum-cropped.png';
import abileneZoo from '../../../../images/abilene/abilene-zoo.jpeg';
import paramountTheater from '../../../../images/abilene/paramount-theater.jpg';
import abileneStatePark from '../../../../images/abilene/abilene-state-park.jpg';

const FiveThingsToDo = () => {
    return (
<main>  
  <div className={styles.contentContainer}>
      <h1 className={styles.pageTitle}>5 Things To Do in Abilene, TX</h1>
      <p className={styles.articleIntro}>
        Explore the highlights of Abilene, TX with our selection of five essential activities. Experience the vibrant history, visit scenic parks, and delve into the cultural landmarks that make Abilene truly distinctive.
      </p>
      <ol>
        {/* First Item */}
        <li className={styles.listItem}>
          <div style={{textAlign: 'center', width: '100%', paddingBottom: '5px'}}>
            <div className={styles.listItemNumber}>5</div>
          </div>
          <div className={styles.listItemContent}>
            <h2 className={styles.listItemTitle}>
              <div className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>5</div>
              Frontier Texas!
            </h2>
          </div>
          <p>Experience the Old West brought to life at <a href="https://www.frontiertexas.com" target="_blank">Frontier Texas!</a>, one of Abilene's premier museums.</p>
          <img src={frontierTX} alt="Frontier Texas!" className={styles.listItemImage} />
          <ul>
            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                <span className={styles.listItemContent}>Immersive history exhibits and holographic displays.</span>
            </li>
            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Spots:</span>
                <span className={styles.listItemContent}>The Experience Theater, offering a 360-degree sensory adventure.</span>
            </li>
            <li className={styles.subItem}><span className={styles.listItemTitle}>Must-Do:</span>
                <span className={styles.listItemContent}>Visit the General Store for authentic Texan souvenirs.</span>
            </li>
          </ul>
          <div className={styles.clear}></div>
        </li>
        {/* Second Item */}
        <li className={styles.listItem}>
          <div style={{textAlign: 'center', width: '100%', paddingBottom: '5px'}}>
            <div className={styles.listItemNumber}>4</div>
          </div>
          <div className={styles.listItemContent}>
            <h2 className={styles.listItemTitle}>
              <div className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>4</div>
              The Grace Museum
            </h2>
          </div>
          <p>Dive into the arts at <a href="https://thegracemuseum.org/" target="_blank">The Grace Museum</a>, housed in a historic building in downtown Abilene.</p>
          <img src={graceMuseumCropped} alt="The Grace Museum" className={styles.listItemImage} />
          <ul>
            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                <span className={styles.listItemContent}>Rotating art exhibitions and educational programs.</span>
            </li>
            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Spots:</span>
                <span className={styles.listItemContent}>The Children's Museum, perfect for young visitors.</span>
            </li>
            <li className={styles.subItem}><span className={styles.listItemTitle}>Cultural Tip:</span>
                <span className={styles.listItemContent}>Attend one of the museum's community nights for live music and art-making workshops.</span>
            </li>
          </ul>
          <div className={styles.clear}></div>
        </li>
        {/* Third Item */}
        <li className={styles.listItem}>
          <div style={{textAlign: 'center', width: '100%', paddingBottom: '5px'}}>
            <div className={styles.listItemNumber}>3</div>
          </div>
          <div className={styles.listItemContent}>
            <h2 className={styles.listItemTitle}>
              <div className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>3</div>
              Abilene Zoo
            </h2>
          </div>
          <p>Connect with wildlife at the <a href="https://abilenezoo.org/" target="_blank">Abilene Zoo</a>, where families can enjoy a fun-filled day.</p>
          <img src={abileneZoo} alt="Abilene Zoo" className={styles.listItemImage} />
          <ul>
            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                <span className={styles.listItemContent}>Over 1,000 animals from around the globe.</span>
            </li>
            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Spots:</span>
                <span className={styles.listItemContent}>The Giraffe Safari, where you can feed these towering creatures.</span>
            </li>
            <li className={styles.subItem}><span className={styles.listItemTitle}>Zoo Tip:</span>
                <span className={styles.listItemContent}>Check out the Adventure Center for interactive exhibits.</span>
            </li>
          </ul>
          <div className={styles.clear}></div>
        </li>
        {/* Fourth Item */}
        <li className={styles.listItem}>
          <div style={{textAlign: 'center', width: '100%', paddingBottom: '5px'}}>
            <div className={styles.listItemNumber}>2</div>
          </div>
          <div className={styles.listItemContent}>
            <h2 className={styles.listItemTitle}>
              <div className={styles.listItemNumberInline} style={{fontWeight:'normal'}}>2</div>
              Paramount Theatre
            </h2>
          </div>
          <p>Step back in time with a show at the <a href="https://www.paramountabilene.com/" target="_blank">Paramount Theatre</a>, an Abilene landmark since 1930.</p>
          <img src={paramountTheater} alt="Paramount Theater" className={styles.listItemImage} />
          <ul>
            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                <span className={styles.listItemContent}>Art Deco architecture and classic film screenings.</span>
            </li>
            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Spots:</span>
                <span className={styles.listItemContent}>The beautifully restored auditorium, with its original murals and decor.</span>
            </li>
            <li className={styles.subItem}><span className={styles.listItemTitle}>Showtime:</span>
                <span className={styles.listItemContent}>Catch a live performance or participate in the theatre's workshops.</span>
            </li>
          </ul>
          <div className={styles.clear}></div>
        </li>
        {/* Fifth Item */}
        <li className={styles.listItem}>
          <div style={{textAlign: 'center', width: '100%', paddingBottom: '5px'}}>
            <div className={styles.listItemNumber}>1</div>
          </div>
          <div className={styles.listItemContent}>
            <h2 className={styles.listItemTitle}>
              <div className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>1</div>
              Abilene State Park
            </h2>
          </div>
          <p>Embrace the great outdoors at <a href="https://tpwd.texas.gov/state-parks/abilene" target="_blank">Abilene State Park</a>, where nature trails and camping sites await.</p>
          <img src={abileneStatePark} alt="Abilene State Park" className={styles.listItemImage} />
          <ul>
            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                <span className={styles.listItemContent}>Scenic paths and opportunities for bird-watching.</span>
            </li>
            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Spots:</span>
                <span className={styles.listItemContent}>The historic swimming pool, fed by natural springs.</span>
            </li>
            <li className={styles.subItem}><span className={styles.listItemTitle}>Park Tip:</span>
                <span className={styles.listItemContent}>Rent a paddle boat and explore the park's waterways.</span>
            </li>
          </ul>
          <div className={styles.clear}></div>
        </li>
      </ol>

      <div style={{width:'100%', textAlign:'center', fontSize: '16px', marginBottom:'10px', marginTop: '0px'}}>
        <div style={{fontWeight:'bold'}}>Sponsored By:</div>
        <div><a href="https://abilenechamber.com/" target="_blank">Abilene Chamber of Commerce</a></div>
      </div>

    </div>
  </main>
    );
};

export default FiveThingsToDo;
