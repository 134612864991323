import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home/home';
import About from './components/about/about';
import Contact from './components/contact/contact';
import LocalGuides from './components/local-guides/local-guides';
import Top10OutdoorsCities from './components/top-10-lists/top-10-outdoors-cities/top-10-outdoors-cities';
import Top10SinglesCities from './components/top-10-lists/top-10-singles-cities/top-10-singles-cities';
import Top10FoodieCities from './components/top-10-lists/top-10-foodie-cities/top-10-foodie-cities';
import FiveThingsToDoInAbilene from './components/local-guides/abilene-tx/5-things-to-do/5-things-to-do';
import FiveThingsToDoInDublinOH from './components/local-guides/dublin-oh/5-things-to-do/5-things-to-do.jsx';
import FiveFunThingsAtIrishFestival from './components/local-guides/dublin-oh/5-fun-things-at-irish-festival/5-fun-things-at-irish-festival.jsx';
import AbileneLanding from './components/local-guides/abilene-tx/landing/landing';
import MyTestGuide from './components/local-guides/test-guide/my-test-guide';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import './App.css'; 
import { useEffect } from 'react';
import GenericLandingPage from './components/local-guides/generic/landing.jsx';

function ScrollToTop({ children }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <>{children}</>;
}

const App = () => {
    return (
        <Router>
            <Header />
              <main>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/local-guides" element={<LocalGuides />} />
                    <Route path="/top-10-outdoors-cities" element={<ScrollToTop><Top10OutdoorsCities /></ScrollToTop>} />
                    <Route path="/top-10-foodie-cities" element={<ScrollToTop><Top10FoodieCities /></ScrollToTop>} />
                    <Route path="/top-10-singles-cities" element={<ScrollToTop><Top10SinglesCities /></ScrollToTop>} />
                    <Route path="/abilene-tx" element={<AbileneLanding />} />                    
                    <Route path="/abilene-tx/5-things-to-do" element={<ScrollToTop><FiveThingsToDoInAbilene /></ScrollToTop>} />
                    <Route path="/5-things-to-do-in-abilene" element={<FiveThingsToDoInAbilene />} />
                    <Route path="/test-guide/my-test-guide" element={<MyTestGuide />} />
                    <Route path="/dublin-oh/5-things-to-do" element={<ScrollToTop><FiveThingsToDoInDublinOH /></ScrollToTop>} />
                    <Route path="/dublin-oh/5-fun-things-at-irish-festival" element={<ScrollToTop><FiveFunThingsAtIrishFestival /></ScrollToTop>} />
                    <Route path="/:cityAndState" element={<ScrollToTop><GenericLandingPage /></ScrollToTop>} />
                </Routes>
              </main>
            <Footer />
        </Router>
    );
};

export default App;
