import React from 'react';

const MyTestGuide = () => {
    return (
        <div>
            <h1>My Test Guide</h1>
            <p>This is a basic React component named MyTestGuide using arrow function.</p>
        </div>
    );
}

export default MyTestGuide;
