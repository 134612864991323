import React from 'react';
import nycOutdoorScene from '../../images/nyc-outdoor-scene.webp';
import foodieScene from '../../images/10-best-cities-foodie-scene.webp';
import singlesScene from '../../images/10-best-cities-for-singles-cover.webp';
import styles from './home.module.css'; 
import { Link } from 'react-router-dom';

const Home = () => {
    return (
      <main className={styles.mainContent}>
        <div className={styles.container}>
          {/* List view content */}
          <section className={styles.contentGrid}>
            <article className={styles.contentCard}>
              <Link id="url1" to="top-10-outdoors-cities">
                <img src={nycOutdoorScene} alt="Best Outdoor Cities" />
                <div className={styles.contentCardBody}>
                  <h2 className={styles.contentCardTitle}>
                    <a>10 Cities in the US with the Best Outdoors Scene in 2024</a>
                  </h2>
                  <p>Explore cities that blend urban living with accessible, vibrant outdoor activities and natural spaces.</p>
                </div>
              </Link>
            </article>
            <article className={styles.contentCard}>
              <Link id="url2" to="top-10-foodie-cities">
                <img src={foodieScene} alt="Best Foodie Cities" />
                <div className={styles.contentCardBody}>
                  <h2 className={styles.contentCardTitle}>
                    <a>10 Cities in the US with the Best Foodie Scene in 2024</a>
                  </h2>
                  <p>Experience the innovation and heritage as we explore cities renowned for culinary excellence.</p>
                </div>
              </Link>
            </article>
            <article className={styles.contentCard}>
              <Link id="url3" to="top-10-singles-cities">
                <img src={singlesScene} alt="Best Singles Cities" />
                <div className={styles.contentCardBody}>
                  <h2 className={styles.contentCardTitle}>
                    <a>10 Cities in the US with the Best Singles Scene in 2024</a>
                  </h2>
                  <p>Discover 2024's top US cities for singles: exciting nightlife, vibrant socials, and unique dating.</p>
                </div>
              </Link>
            </article>
          </section>
        </div>
      </main>
    );
};

export default Home;
