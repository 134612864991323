import React from 'react';
import styles from './footer.module.css';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.container}>
                <p>info@citytrendz.net</p>
                <p>&copy; {new Date().getFullYear()} CityTrendz.net. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
