import React from 'react';
import styles from './contact.module.css'; // Import the CSS module

const Contact = () => {
    return (
        <main>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>Contact Us</h1>
                <p className={styles.articleIntro}>
                    Is there a Top 10 List that you would like to see on CityTrendz.net? Are you interested in a Local Guide page for your city?
                    Contact us at <a href="mailto:info@citytrendz.net">info@CityTrendz.net</a>!
                    <br/><br/><br/>
                </p>
            </div>
        </main>
    );
};

export default Contact;
