import React from 'react';
import './index.css';
import imageComingSoonCamera from './IMAGE-COMING-SOON-CAMERA.png';

const FiveFunThingsAtIrishFestival = () => {
    return (
        <main>  
            <div className="content-container">
                <h1 className="page-title">5 Fun Things To Do At Dublin Irish Festival</h1>
                <p className="article-intro">
                Dive into the vibrant spirit of Ireland right in Dublin, Ohio. Explore a weekend packed with live music, cultural experiences, and family-friendly activities at the Dublin Irish Festival.
                </p>
                <ol>
                    <li className="list-item">
                        <div style={{textAlign: 'center', width: '100%', paddingBottom: '5px'}}>
                            <div className="list-item-number">1</div>
                        </div>
                        <div className="list-item-content">
                            <h2 className="list-item-title">Music and Dance Shows</h2>
                            <p>Enjoy non-stop entertainment with over 60 acts on 7 different stages, featuring traditional Irish music, modern Celtic rock, and mesmerizing dance performances.</p>
                            <img src={imageComingSoonCamera} alt="Irish Music and Dance" className="list-item-image" />
                            <ul>
                                <li className="sub-item">
                                    <span className="list-item-title">Highlight:</span>
                                    <span className="list-item-content">Experience live performances from internationally acclaimed musicians and dancers.</span>
                                </li>
                                <li className="sub-item">
                                    <span className="list-item-title">Must See:</span>
                                    <span className="list-item-content">Don't miss the Riverdance performances, a festival favorite.</span>
                                </li>
                                <li className="sub-item">
                                    <span className="list-item-title">Tip:</span>
                                    <span className="list-item-content">Arrive early to get a good spot near the main stage.</span>
                                </li>
                            </ul>
                        </div>
                        <div className="clear"></div>
                    </li>

                    <li className="list-item">
                        <div style={{textAlign: 'center', width: '100%', paddingBottom: '5px'}}>
                            <div className="list-item-number">2</div>
                        </div>
                        <div className="list-item-content">
                            <h2 className="list-item-title">Cultural Workshops and Presentations</h2>
                            <p>Participate in workshops and presentations to learn about Irish culture, from language classes to storytelling sessions.</p>
                            <img src={imageComingSoonCamera} alt="Cultural Workshops" className="list-item-image" />
                            <ul>
                                <li className="sub-item">
                                    <span className="list-item-title">Highlight:</span>
                                    <span className="list-item-content">Join in on a hands-on Irish cooking class.</span>
                                </li>
                                <li className="sub-item">
                                    <span className="list-item-title">Must Try:</span>
                                    <span className="list-item-content">Take a beginner's Gaelic language session.</span>
                                </li>
                                <li className="sub-item">
                                    <span className="list-item-title">Note:</span>
                                    <span className="list-item-content">Check the schedule for workshop times and locations.</span>
                                </li>
                            </ul>
                        </div>
                        <div className="clear"></div>
                    </li>

                    <li className="list-item">
                        <div style={{textAlign: 'center', width: '100%', paddingBottom: '5px'}}>
                            <div className="list-item-number">3</div>
                        </div>
                        <div className="list-item-content">
                            <h2 className="list-item-title">Irish Food and Drink</h2>
                            <p>Savor traditional Irish fare and contemporary culinary delights, paired with a pint of Irish beer or a cup of Irish tea.</p>
                            <img src={imageComingSoonCamera} alt="Irish Food and Drink" className="list-item-image" />
                            <ul>
                                <li className="sub-item">
                                    <span className="list-item-title">Highlight:</span>
                                    <span className="list-item-content">Try the authentic Irish stew and soda bread.</span>
                                </li>
                                <li className="sub-item">
                                    <span className="list-item-title">Recommended:</span>
                                    <span className="list-item-content">Sample a variety of Irish whiskeys.</span>
                                </li>
                                <li className="sub-item">
                                    <span className="list-item-title">Tip:</span>
                                    <span className="list-item-content">Look for food pairing suggestions at the tasting booths.</span>
                                </li>
                            </ul>
                        </div>
                        <div className="clear"></div>
                    </li>

                    <li className="list-item">
                        <div style={{textAlign: 'center', width: '100%', paddingBottom: '5px'}}>
                            <div className="list-item-number">4</div>
                        </div>
                        <div className="list-item-content">
                            <h2 className="list-item-title">Arts and Crafts</h2>
                            <p>Explore a marketplace of artisans showcasing their crafts, including pottery, jewelry, and traditional Irish clothing.</p>
                            <img src={imageComingSoonCamera} alt="Irish Arts and Crafts" className="list-item-image" />
                            <ul>
                                <li className="sub-item">
                                    <span className="list-item-title">Must See:</span>
                                    <span className="list-item-content">Watch live demonstrations of glass blowing and lace making.</span>
                                </li>
                                <li className="sub-item">
                                    <span className="list-item-title">Tip:</span>
                                    <span className="list-item-content">Take home a piece of handmade jewelry as a unique souvenir.</span>
                                </li>
                                <li className="sub-item">
                                    <span className="list-item-title">Local Craft:</span>
                                    <span className="list-item-content">Meet local artisans and hear the stories behind their traditional crafts.</span>
                                </li>
                            </ul>
                        </div>
                        <div className="clear"></div>
                    </li>

                    <li className="list-item">
                        <div style={{textAlign: 'center', width: '100%', paddingBottom: '5px'}}>
                            <div className="list-item-number">5</div>
                        </div>
                        <div className="list-item-content">
                            <h2 className="list-item-title">Family Fun Activities</h2>
                            <p>Bring the whole family for a fun-filled day with children's games, face painting, and interactive educational activities.</p>
                            <img src={imageComingSoonCamera} alt="Family Fun Activities" className="list-item-image" />
                            <ul>
                                <li className="sub-item">
                                    <span className="list-item-title">Highlight:</span>
                                    <span className="list-item-content">Don't miss the children's magic shows and puppet theater.</span>
                                </li>
                                <li className="sub-item">
                                    <span className="list-item-title">For the Kids:</span>
                                    <span className="list-item-content">Participate in the 'Little Leprechaun Land' activities designed for young children.</span>
                                </li>
                                <li className="sub-item">
                                    <span className="list-item-title">Tip:</span>
                                    <span className="list-item-content">Check out the family-oriented workshops on Irish folklore and myths.</span>
                                </li>
                            </ul>
                        </div>
                        <div className="clear"></div>
                    </li>
                </ol>
            </div>
        </main>
    );
};

export default FiveFunThingsAtIrishFestival;
