import React from 'react';
import styles from './landing.module.css';
import { Link } from 'react-router-dom';
import fiveThingsToDoInAbilene from '../../../../images/5-things-to-do-in-abilene-2.webp';

const Landing = () => {
    return (
        <main>
            <div className={styles.container}>
                <section className={styles.contentGrid}>
                    <article className={styles.contentCard}>
                        <Link to="/abilene-tx/5-things-to-do">
                            <img src={fiveThingsToDoInAbilene} alt="5 Things To Do in Abilene, TX" />
                            <div className={styles.contentCardBody}>
                                <h2 className={styles.contentCardTitle}><a id="url4" href="./content/local-guide-abilene.html">5 Things To Do in Abilene, TX</a></h2>
                                <p>Explore Abilene's offerings with choices that include serene parks, lively cultural spots, and notable historical sites.</p>
                            </div>
                        </Link>
                    </article>
                </section>
            </div>
        </main>
    );
};

export default Landing;
