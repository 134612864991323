import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './landing.module.css';

const CityLandingPage = () => {
    const { cityAndState } = useParams();

    if (!cityAndState) {
        return (<div></div>);
    }

    const match = cityAndState.match(/(.+)-([a-zA-Z]{2})$/);

    if (!match) {
        return (<div></div>);
    }

    const city = match[1].split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    const state = match[2].toUpperCase();

    return (
      <main>
          <div className={styles.contentContainer}>
              <h1 className={styles.pageTitle}>Coming Soon</h1>
              <p className={styles.articleIntro} style={{textAlign:'center'}}>
              
                Welcome to the future Local Guide landing page for {city}, {state}!
              </p>
          </div>
      </main>
  );
};

export default CityLandingPage;
