import React from 'react';
import styles from './top-10-outdoors.module.css';
import nycOutdoorsScene from '../../../images/nyc-outdoor-scene.webp';
import denverOutdoorsScene from '../../../images/denver-outdoor-scene.webp';
import pdxOutdoorsScene from '../../../images/portland-outdoor-scene.webp';
import sfOutdoorsScene from '../../../images/sf-outdoor-scene.webp';
import seattleOutdoorsScene from '../../../images/seattle-outdoor-scene.webp';
import miamiOutdoorsScene from '../../../images/miami-outdoor-scene.webp';
import minneapolisOutdoorsScene from '../../../images/minneapolis-outdoor-scene.webp';
import ashevilleOutdoorsScene from '../../../images/asheville-outdoor-scene-2.webp';
import atxOutdoorsScene from '../../../images/austin-outdoor-scene-2.webp';
import laOutdoorsScene from '../../../images/la-outdoor-scene-2.webp';

const Top10OutdoorsCities = () => {
    return (
        <main>  
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>10 Cities in the US with the Best Outdoors Scene in 2024</h1>
                <p className={styles.articleIntro}>
                    Discover the top 10 cities for outdoor enthusiasts in our guide to America’s best urban and wilderness landscapes. Explore vibrant parks and expansive natural areas as we highlight the outdoor scenes to watch in 2024.
                </p>
                <ol>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>10</span>
                            New York City, NY
                        </h2>
                        <p>From Central Park to the High Line, NYC offers diverse outdoor experiences.</p>
                        <img src={nycOutdoorsScene} alt="New York City Outdoor Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Diverse parks and innovative green spaces.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Spots:</span>
                                <span className={styles.listItemContent}><a href="https://www.centralparknyc.org/">Central Park</a> for peaceful escapes, the <a href="https://www.thehighline.org/">High Line</a> for urban strolls.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Outdoor Experience:</span>
                                <span className={styles.listItemContent}>Biking along the Hudson River Park, kayaking in the East River.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>9</span>
                            Denver, CO
                        </h2>
                        <p>Denver's mountainous backdrop and city parks provide a perfect outdoor blend.</p>
                        <img src={denverOutdoorsScene} alt="Denver Outdoor Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Access to the Rockies and thriving urban parklands.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Spots:</span>
                                <span className={styles.listItemContent}><a href="https://www.nps.gov/romo/index.htm" target="_blank">Rocky Mountain National Park</a>, <a href="https://www.denver.org/listing/city-park/6822/" target="_blank">City Park</a> for <a href="https://cityparkjazz.org/" target="_blank">City Park Jazz</a>.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Outdoor Experience:</span>
                                <span className={styles.listItemContent}>Hiking and mountain biking in the foothills, skiing nearby.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>8</span>
                            Portland, OR
                        </h2>
                        <p>Portland is a green city packed with parks and bike paths alongside rivers.</p>
                        <img src={pdxOutdoorsScene} alt="Portland Outdoor Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>A network of bike paths and waterfront parks.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Spots:</span>
                                <span className={styles.listItemContent}><a href="https://www.portland.gov/parks/forest-park" target="_blank">Forest Park</a>, the largest urban forest in the US, <a href="https://www.portland.gov/parks/governor-tom-mccall-waterfront-park" target="_blank">Waterfront Park</a>.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Outdoor Experience:</span>
                                <span className={styles.listItemContent}>Kayaking on the Willamette, extensive urban biking trails.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>7</span>
                            San Francisco, CA
                        </h2>
                        <p>Iconic landscapes and extensive parks offer unique urban outdoor experiences.</p>
                        <img src={sfOutdoorsScene} alt="San Francisco Outdoor Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}><a href="https://sfrecpark.org/770/Golden-Gate-Park" target="_blank">Golden Gate Park</a> and the <a href="https://presidio.gov/" target="_blank">Presidio</a> provide city escape.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Spots:</span>
                                <span className={styles.listItemContent}>Golden Gate Bridge vistas, Alcatraz Island tours.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Outdoor Experience:</span>
                                <span className={styles.listItemContent}>Sailing in the bay, hiking in <a href="https://www.nps.gov/muwo/index.htm" target="_blank">Muir Woods</a>.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>6</span>
                            Seattle, WA
                        </h2>
                        <p>Seattle combines lush greenery with water-based activities and stunning mountain views.</p>
                        <img src={seattleOutdoorsScene} alt="Seattle Outdoor Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Emerald City with abundant parks and waterfront access.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Spots:</span>
                                <span className={styles.listItemContent}><a href="https://www.seattle.gov/parks/find/parks/discovery-park" target="_blank">Discovery Park</a> for trails, <a href="https://www.seattle.gov/parks/allparks/lake-union-park" target="_blank">Lake Union</a> for paddle sports.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Outdoor Experience:</span>
                                <span className={styles.listItemContent}>Whale watching in Puget Sound, hiking in the Cascades.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>5</span>
                            Miami, FL
                        </h2>
                        <p>Miami's sunny beaches and vibrant natural reserves offer year-round outdoor fun.</p>
                        <img src={miamiOutdoorsScene} alt="Miami Outdoor Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Beaches and wetlands within city limits.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Spots:</span>
                                <span className={styles.listItemContent}><a href="https://www.nps.gov/ever/index.htm">Everglades National Park</a>, South Beach for sunbathing and swimming.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Outdoor Experience:</span>
                                <span className={styles.listItemContent}>Snorkeling in <a href="https://www.nps.gov/bisc/index.htm" target="_blank">Biscayne National Park</a>, kayaking in the Everglades.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>4</span>
                            Minneapolis, MN
                        </h2>
                        <p>Renowned for its bike-friendly streets and plethora of lakes, Minneapolis is a haven for cyclists and paddlers.</p>
                        <img src={minneapolisOutdoorsScene} alt="Minneapolis Outdoor Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>City of Lakes with vibrant biking culture.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Spots:</span>
                                <span className={styles.listItemContent}><a href="https://www.minneapolisparks.org/parks-destinations/parks-lakes/minneapolis_chain_of_lakes_regional_park/" target="_blank">Chain of Lakes</a>, <a href="https://www.minneapolisparks.org/parks-destinations/parks-lakes/minnehaha_regional_park/" target="_blank">Minnehaha Park</a>.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Outdoor Experience:</span>
                                <span className={styles.listItemContent}>Canoeing and fishing on the lakes, winter skiing and snowboarding.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>3</span>
                            Asheville, NC
                        </h2>
                        <p>Asheville offers a unique blend of artistic downtown and access to the Appalachian wilderness.</p>
                        <img src={ashevilleOutdoorsScene} alt="Asheville Outdoor Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Artistic community with a gateway to Appalachian adventures.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Spots:</span>
                                <span className={styles.listItemContent}><a href="http://www.nps.gov/blri/" target="_blank">Blue Ridge Parkway</a> for scenic drives, downtown Asheville for urban art.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Outdoor Experience:</span>
                                <span className={styles.listItemContent}>Hiking and camping in the Great Smoky Mountains, white-water rafting.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>2</span>
                            Austin, TX
                        </h2>
                        <p>Austin's outdoor scene is as vibrant as its music, with lakes and trails that invite exploration.</p>
                        <img src={atxOutdoorsScene} alt="Austin Outdoor Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Live music outdoors and water-bound adventures.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Spots:</span>
                                <span className={styles.listItemContent}><a href="https://www.austintexas.gov/department/barton-springs-pool" target="_blank">Barton Springs Pool</a>, <a href="https://www.austintexas.gov/department/zilker-metropolitan-park" target="_blank">Zilker Metropolitan Park</a> for festivals and leisure.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Outdoor Experience:</span>
                                <span className={styles.listItemContent}>Paddleboarding on <a href="https://www.austintexas.gov/page/lady-bird-lake" target="_blank">Lady Bird Lake</a>, hiking the <a href="https://www.austintexas.gov/department/barton-creek-greenbelt">Greenbelt</a>.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>1</span>
                            Los Angeles, CA
                        </h2>
                        <p>LA's unique geography offers beaches, mountains, and urban parks all within a short drive.</p>
                        <img src={laOutdoorsScene} alt="Los Angeles Outdoor Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Diverse landscapes from beaches to hiking trails.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Notable Spots:</span>
                                <span className={styles.listItemContent}><a href="https://www.laparks.org/griffithpark/">Griffith Park</a> for city views, Santa Monica Beach for ocean activities.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Outdoor Experience:</span>
                                <span className={styles.listItemContent}>Surfing in Malibu, hiking in the <a href="https://www.nps.gov/samo/index.htm">Santa Monica Mountains</a>.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                </ol>
            </div>
        </main>
    );
};

export default Top10OutdoorsCities;
