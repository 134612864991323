import React from 'react';
import styles from './top-10-singles-cities.module.css';
import nycSinglesScene from '../../../images/nyc-single-scene-4.webp';
import laSinglesScene from '../../../images/la-single-scene-2.webp';
import chicagoSinglesScene from '../../../images/chicago-single-scene-2.webp';
import sfSinglesScene from '../../../images/sfo-single-scene.webp';
import noSinglesScene from '../../../images/no-single-scene-3.webp';
import pdxSinglesScene from '../../../images/portland-single-scene-2.webp';
import atxSinglesScene from '../../../images/atx-single-scene.webp';
import seattleSinglesScene from '../../../images/seattle-single-scene-2.webp';
import miamiSinglesScene from '../../../images/miami-single-scene.webp';
import phillySinglesScene from '../../../images/philly-single-scene-3.webp';

const Top10SinglesCities = () => {
    return (
        <main>
            <div className={styles.contentContainer}>
                <h1 className={styles.pageTitle}>10 Cities in the US with the Best Singles Scene in 2024</h1>
                <p className={styles.articleIntro}>
                    Explore our guide to the top 10 US cities for singles, showcasing vibrant social scenes and ideal spots nationwide. Join us as we reveal the best locales for dating to look out for in 2024.
                </p>
                <ol>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>10</span>
                            New York City, NY
                        </h2>
                        <p>New York City offers an unrivaled mix of entertainment, bustling nightlife, and diverse dating scenes, making it a top spot for singles.</p>
                        <img src={nycSinglesScene} alt="New York City Singles Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Vibrant nightlife and a variety of dating spots.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Best Spots:</span>
                                <span className={styles.listItemContent}>Cocktail bars in the East Village, exclusive clubs in Chelsea.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Dating Experience:</span>
                                <span className={styles.listItemContent}>Rooftop dinners, boat cruises around Manhattan for romantic evenings.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>9</span>
                            Los Angeles, CA
                        </h2>
                        <p>Los Angeles is famed for its vibrant singles scene, with endless opportunities for mingling in its sun-soaked settings.</p>
                        <img src={laSinglesScene} alt="Los Angeles Singles Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Outdoor events and trendy nightlife options.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Best Spots:</span>
                                <span className={styles.listItemContent}>Beach parties in Santa Monica, elite clubs in Hollywood.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Dating Experience:</span>
                                <span className={styles.listItemContent}>Celebrity-hosted parties, exclusive wine tastings.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>8</span>
                            Chicago, IL
                        </h2>
                        <p>Chicago's dynamic singles scene offers a blend of Midwestern charm and vibrant urban settings, ideal for meeting new people.</p>
                        <img src={chicagoSinglesScene} alt="Chicago Singles Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Lively social atmosphere and diverse venues.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Best Spots:</span>
                                <span className={styles.listItemContent}>River North bars, Lakeview music venues.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Dating Experience:</span>
                                <span className={styles.listItemContent}>Jazz clubs, romantic walks along the <a href="https://www.chicago.gov/city/en/sites/chicagoriverwalk/home.html.html">Chicago Riverwalk</a>.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>7</span>
                            San Francisco, CA
                        </h2>
                        <p>San Francisco's singles scene thrives on its cultural diversity and abundance of social activities, perfect for connecting with like-minded individuals.</p>
                        <img src={sfSinglesScene} alt="San Francisco Singles Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Rich cultural experiences and active outdoor life.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Best Spots:</span>
                                <span className={styles.listItemContent}>Wine bars in the Marina, artisan coffee shops in Silicon Valley.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Dating Experience:</span>
                                <span className={styles.listItemContent}>Biking across the Golden Gate Bridge, upscale dining in the Mission District.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>6</span>
                            New Orleans, LA
                        </h2>
                        <p>New Orleans offers a unique singles scene with its blend of Southern charm and vibrant nightlife, perfect for those looking for fun and romance.</p>
                        <img src={noSinglesScene} alt="New Orleans Singles Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Music-filled streets and festive atmosphere.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Best Spots:</span>
                                <span className={styles.listItemContent}>Jazz clubs on <a href="https://www.neworleans.com/plan/streets/frenchmen-street/">Frenchmen Street</a>, lively bars in the <a href="https://www.neworleans.com/plan/neighborhoods/french-quarter/">French Quarter</a>.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Dating Experience:</span>
                                <span className={styles.listItemContent}>Riverboat cruises, Mardi Gras celebrations.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>5</span>
                            Portland, OR
                        </h2>
                        <p>Portland's relaxed vibe and friendly community make it an ideal city for singles looking to meet new people in a laid-back setting.</p>
                        <img src={pdxSinglesScene} alt="Portland Singles Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Eco-conscious community and vibrant arts scene.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Best Spots:</span>
                                <span className={styles.listItemContent}>Craft breweries in East Portland, indie music venues in Alberta.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Dating Experience:</span>
                                <span className={styles.listItemContent}>Hiking in <a href="https://www.washoecounty.gov/parks/parks/park_directory_pages/north_region/forest_park.php">Forest Park</a>, poetry readings in local cafes.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>4</span>
                            Austin, TX
                        </h2>
                        <p>Austin's energetic and youthful singles scene is bolstered by the city's famous live music and vibrant cultural life.</p>
                        <img src={atxSinglesScene} alt="Austin Singles Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Live music capital with a thriving nightlife.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Best Spots:</span>
                                <span className={styles.listItemContent}>Music venues on <a href="https://6street.com/">Sixth Street</a>, popular bars on Rainey Street.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Dating Experience:</span>
                                <span className={styles.listItemContent}>Outdoor concerts at <a href="https://www.austintexas.gov/department/zilker-metropolitan-park">Zilker Park</a>, paddleboarding on <a href="https://www.austintexas.gov/page/lady-bird-lake">Lady Bird Lake</a>.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>3</span>
                            Seattle, WA
                        </h2>
                        <p>Seattle's rich cultural tapestry and inclusive atmosphere make it a welcoming place for singles from all walks of life.</p>
                        <img src={seattleSinglesScene} alt="Seattle Singles Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Progressive community and high engagement in outdoor activities.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Best Spots:</span>
                                <span className={styles.listItemContent}>Coffee shops in Capitol Hill, microbreweries in Ballard.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Dating Experience:</span>
                                <span className={styles.listItemContent}>Kayaking on Lake Union, cultural dates at the <a href="https://www.seattleartmuseum.org/">Seattle Art Museum</a>.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>2</span>
                            Miami, FL
                        </h2>
                        <p>Miami's eclectic mix of cultures and year-round sunny weather create a perfect backdrop for singles looking to enjoy nightlife and meet new people.</p>
                        <img src={miamiSinglesScene} alt="Miami Singles Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Vibrant nightlife and diverse cultural mix.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Best Spots:</span>
                                <span className={styles.listItemContent}>Beachfront bars in South Beach, Latin dance clubs in Little Havana.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Dating Experience:</span>
                                <span className={styles.listItemContent}>Boat parties in Biscayne Bay, art walks in <a href="https://wynwoodmiami.com/">Wynwood</a>.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                    <li className={styles.listItem}>
                        <div style={{height: 6}}></div>
                        <h2>
                            <span className={styles.listItemNumberInline} style={{fontWeight: 'normal'}}>1</span>
                            Philadelphia, PA
                        </h2>
                        <p>Philadelphia's rich history and vibrant social scene provide a unique setting for singles to explore and connect.</p>
                        <img src={phillySinglesScene} alt="Philadelphia Singles Scene" className={styles.listItemImage} />
                        <ul>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Highlight:</span>
                                <span className={styles.listItemContent}>Historical landmarks paired with modern social venues.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Best Spots:</span>
                                <span className={styles.listItemContent}>Brewpubs in <a href="https://www.visitphilly.com/areas/philadelphia-neighborhoods/fishtown/">Fishtown</a>, eclectic eateries in East Passyunk.</span>
                            </li>
                            <li className={styles.subItem}><span className={styles.listItemTitle}>Dating Experience:</span>
                                <span className={styles.listItemContent}>Strolls through the Museum District, vibrant nightlife in Center City.</span>
                            </li>
                        </ul>
                        <div className={styles.clear}></div>
                    </li>
                </ol>
            </div>
        </main>
    );
};

export default Top10SinglesCities;
